/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Language
import { getLanguage } from 'services/language'

// Components
// import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import Form from 'components/Reviews/Form'
import Rating from 'components/Reviews/Rating'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import HeroAlt from 'components/elements/HeroAlt'

const Container = styled.div`
  position: relative;
  z-index: 2;

  @media (min-width: 992px) {
    top: -150px;
  }

  @media (max-width: 991px) {
    top: -100px;
  }
`

const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: #979797;
`

const PageTemplate = ({
  data: {
    page: { acf },
  },
}) => {
  const formId = getLanguage() === 'nl_NL' ? 9 : 10

  return (
    <Layout>
      {/* <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      /> */}

      <section>
        <HeroAlt small image={acf.banner.image} />
      </section>

      <section>
        <Container className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <BlockDefault>
                <div className="row pb-3">
                  <div className="col-md-6 col-lg-8 pr-lg-4">
                    <ParseContent content={acf.top.description} />
                  </div>
                  <div className="col-md-6 col-lg-4 d-flex align-items-center mt-4 mt-lg-0 justify-content-center">
                    <Rating fields={acf.top} />
                  </div>
                </div>
                <Border className="mt-5" />
                <div className="row">
                  <div className="col-12">
                    <Form id={formId} fields={acf.form} />
                  </div>
                </div>
              </BlockDefault>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        top {
          description
          score_title
        }

        form {
          score_title_bad
          score_title_good
        }
      }
    }
  }
`

export default PageTemplate
